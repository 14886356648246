import { SelectableClient } from '../../../models/selectableclient';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HeaderService } from '../../../header/header.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ClientProfileService } from '../../client';

export const isAustralian = (clientCode: string): boolean => {
  return clientCode.toLowerCase().endsWith('au');
};

@Component({
  selector: 'app-competition-disclaimer',
  templateUrl: './competition-disclaimer.component.html',
  styleUrls: ['./competition-disclaimer.component.scss'],
})
export class CompetitionDisclaimerComponent implements OnInit, OnDestroy {
  @Input()
  manuallyShowDisclaimer: boolean = false;
  subscriptions = [];
  selectedClient: SelectableClient;
  selectedLocaleFilterDefault: any;
  constructor(
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private translateService: TranslateService,
    private clientProfileService: ClientProfileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.selectedLocaleFilterDefault =
      this.clientProfileService.selectedLocaleFilterDefault;
    this.subscriptions.push(
      this.clientProfileService.selectedLocaleSubject.subscribe(
        (filterValue) => {
          this.selectedLocaleFilterDefault = filterValue;
        }
      )
    );
    if (
      this.authenticationService._userInfoView &&
      this.authenticationService._userInfoView.SelectedClient
    ) {
      this.selectedClient = {
        ...this.authenticationService._userInfoView.SelectedClient,
      };
    }
    this.subscriptions.push(
      this.authenticationService.selectedClientId.subscribe((clientid) => {
        if (clientid) {
          this.selectedClient = {
            ...this.authenticationService._userInfoView.SelectedClient,
          };
        }
      })
    );
  }

  showDisclaimer() {
    return (
      environment.enableCompetitiveDisclaimer &&
      (this.headerService.manuallyShowCompetitionDisclaimer ||
        (this.selectedClient &&
          this.selectedClient.RequiresCompetitionDisclaimer))
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  getButtonText = () => {
    if (this.headerService.manuallyShowCompetitionDisclaimer) {
      return this.translateService.instant('main.disclaimers.close');
    } else {
      return this.translateService.instant('main.disclaimers.agree');
    }
  };

  showExitButton = () => {
    return !this.headerService.manuallyShowCompetitionDisclaimer;
  };

  getExitButtonText = () => {
    return this.translateService.instant('main.disclaimers.exit');
  };

  exitApplication = () => {
    this.router.navigate(['signout']);
  };

  recordDisclaimerAcceptance = () => {
    if (!this.headerService.manuallyShowCompetitionDisclaimer) {
      this.headerService.setCompetitionDisclaimerAcceptance().subscribe((r) => {
        this.selectedClient.RequiresCompetitionDisclaimer = false;
        this.authenticationService._userInfoView.updateSelectedClient(
          this.selectedClient
        );
      });
    } else {
      this.headerService.manuallyShowCompetitionDisclaimer = false;
    }
  };
}
